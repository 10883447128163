<template>
	<div class="my-container p-all-20">
		<myHeader title="" :height="750">
			<template slot="content">
		<div class="top-box d-space-column">
			<!-- 头部背景 -->
			<div class="top-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="side-border p-all-10">
			</div>
			<div class="pool-box max-bg p-all m-bottom d-space-row justify-start">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">{{i18n.title}}</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>
				<div class="pool-logo m-right">
					<img src="../../assets/image/pool-logo.png" alt="">
				</div>
				<div class="pool-info d-space-column align-start">
					<span>{{ linkWallet }}</span>
					<!-- <div class="node d-center-row m-top-10">
						<span>普通节点</span>
					</div> -->
				</div>
			</div>
			<div class="bottom-border max-bg"></div>
		</div>
		<div class="earn-box">
			<div class="d-space-row">
				<span>{{i18n.tgsy}}</span>
			</div>
			<div class="list-box p-all m-top">
				<div class="stat-list">
					<van-list v-model="loading" :finished="finished" :loading-text="$t('app.loading')"
						:finished-text="$t('app.finished')" @load="loadMore()">
						<template v-for="(item, index) in list">
							<div class="m-bottom">
								<myRecordItem :item="item" :key="item.id"></myRecordItem>
							</div>
						</template>
					</van-list>
				</div>
			</div>
		</div>

	</template>
</myHeader>
		
	</div>
</template>

<script>
import {
	mapGetters,
} from "vuex";
import myRecordItem from '../components/myRecordItem.vue'
import {groupIncome} from '@/api/account.js'
export default {
	components: {
		myRecordItem
	},
	data() {
		return {
			list:[],
			logs: [],
			pageNum: 1,
			pageSize: 10,
			loading: true,
			finished: false,
		};
	},
	created() {
		//this.mock()
		this.getList()
	},
	computed: {
		...mapGetters(['address']),
		linkWallet() {
			if (this.address) {
				return this.$dapp.ellipseAddress(this.address, 5)
			}
		},
		i18n() {
			return this.$t('pool')
		}
	},
	methods: {
		async getList() {

			const { err, res } = await groupIncome({ pageNum: this.pageNum, pageSize: this.pageSize });
			if (err === null) {
				this.list = this.list.concat(res.records);
				let length = res.records.length
				if (length < this.pageSize) {
					this.finished = true;
				} else {
					this.loading = false
				}
			}

		},
		mock() {
			for (var i = 0; i < 13; i++) {
				this.logs.push({
					id: i,
					name: '模拟数据' + i,
					image: 'https://cdn.uviewui.com/uview/swiper/swiper1.png',
					status: i,
					price: 600 * i,
				})
			}
		},
		loadMore() {
			this.pageNum++
			this.getList()
		}
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.top-box {
		width: 100%;
		// height: auto;
		position: relative;
		box-sizing: border-box;
		padding-top: 50px;

		.top-border-bg {
			width: 100%;
			height: 300px;
			background: url('../../assets/image/border-bg.png');
			position: absolute;
			left: 0;
			top: 0;
		}

		.side-border {
			width: 100%;
			height: 70px;
			border-left: 2px solid #4BB005;
			border-right: 2px solid #4BB005;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.pool-box {
			width: 643px;
			height: 279px;
			background: url('../../assets/image/pool-bg.png');
			position: relative;
			box-sizing: border-box;
			padding-top: 60px;

			.title-box {
				width: 643px;
				height: 50px;
				// border: 1px solid red;
				position: absolute;
				top: -26px;
				left: 0;

				img {
					width: 54px;
					height: 30px;
				}

				font-size: 42px;
			}
		}

		.pool-logo {
			img {
				width: 147px;
				height: 147px;
			}
		}

		.pool-info {

			.node {
				width: 182px;
				height: 49px;
				background: rgba(38, 54, 172, 0.5);
				border-radius: 10px;
			}
		}
	}

	.earn-box {
		width: 100%;
		height: calc(100vh - 600px);
		border-left: 2px solid #4BB005;
		border-right: 2px solid #4BB005;
		box-sizing: border-box;
		padding: 30px 20px;

		.list-box {
			width: 100%;
			height: calc(100vh - 720px);
			background: rgba(82, 121, 42, 0.4);
			box-shadow: 0px 0px 13px 0px rgba(65, 126, 150, 0.25);
			border-radius: 12px;

			.stat-list {
				border-radius: 8px;
				position: relative;

				.van-list {
					width: 100%;
					height: calc(100vh - 750px);
					overflow: scroll;
				}
			}
		}
	}

	.bottom-border {
		width: 100%;
		height: 2px;
		background: url('../../assets/image/bottom-border.png');
	}
}
</style>