<template>
	<div class="item-container">
		<div class="team-item d-space-row">
			<div class="d-space-column align-start">
				<span class="f-20 t-time">{{ item.createTime }}</span>
				<span>{{ item.resource.substring(0,6) }}******{{ item.resource.substring(item.resource.length-6,item.resource.length) }}</span>
			</div>
			<div class="right">
				<span class="f-30">+ {{item.amount}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			},
			time: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				default: ''
			},
			value: {
				type: String,
				default: ''
			},
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.item-container {
		width: 100%;

		.t-time {
			color: #86B871;
		}

		.right {
			color: #F0FF6E;
		}
	}
</style>